<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(save)"
    >
      <CRating
        v-model="rating"
        :disabled="disabled"
      />

      <b-input-validation
        v-model="content"
        rules="required"
        :label="$t('reviews')"
        vid="review"
        input-type="textarea"
        class="mt-3"
        maxlength="500"
        rows="5"
        :placeholder="placeholders.review.review"
        :disabled="disabled"
      />

      <b-input-validation
        v-model="reply"
        :label="$t('reply')"
        input-type="textarea"
        rows="2"
        class="ml-6 mt-2"
        :placeholder="placeholders.review.reply"
        :disabled="disabled"
      />

      <b-button
        class="mt-2"
        type="is-primary"
        :disabled="loading || disabled"
        :loading="loading"
        tag="input"
        native-type="submit"
        :value="$t('save')"
      >
        {{ $t("save") }}
      </b-button>
    </form>
  </ValidationObserver>
</template>

<script>
const CRating = () => import("@/components/Rating");

export default {
  components: {
    CRating
  },
  props: {
    review: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      rating: this.review.rating,
      content: this.review.review,
      reply: this.review.reply,
      disabled: false
    };
  },
  created() {
    if (!this.$can("update", "catalog.reviews")) this.disabled = true;
  },
  methods: {
    save() {
      this.loading = true;
      this.$axios
        .put(`reviews/${this.review.id}`, {
          review: this.content,
          reply: this.reply,
          rating: this.rating
        })
        .then(res => {
          this.$root.notify(this.$t("successfully_updated"), "is-success");
          this.$bus.$emit("update-review", {
            ...this.review,
            review: res.data.review,
            reply: res.data.reply,
            rating: res.data.rating
          });
          this.closeDrawer();
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
