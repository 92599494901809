<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ title }}
    </p>
    <PageForm
      :action="action"
      :page="options.page"
      :index="options.index"
    />
  </div>
</template>

<script>
import PageForm from "@/components/forms/Page";

export default {
  components: {
    PageForm
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: "",
      action: ""
    };
  },
  created() {
    if (this.options.action == "read") {
      if (this.$can("update", "settings.pages")) {
        this.action = "update";
        this.title = this.$t("edit_noun");
      } else if (this.$can("read", "settings.pages")) {
        this.action = "read";
        this.title = this.$tc("page", 1);
      }
    } else if (this.$can("create", "settings.pages")) {
      this.action = "create";
      this.title = this.$t("new_page");
    }
  }
};
</script>

