<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(saveVar)"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column is-6-tablet is-12-mobile">
          <b-select-validation
            v-model="myVar.type"
            rules="required"
            vid="type"
            :disabled="isShow"
            :label="$tc('type', 1)"
          >
            <template>
              <option
                v-for="opt in types"
                :key="opt"
                :value="opt"
              >
                {{
                  opt
                }}
              </option>
            </template>
          </b-select-validation>
        </div>

        <div class="column is-6-tablet is-12-mobile">
          <b-input-validation
            v-model="myVar.var"
            rules="required"
            :disabled="isShow"
            :label="$tc('variable', 1)"
            vid="var"
            :placeholder="placeholders.config_values.variable"
          />
        </div>

        <div class="column is-12">
          <b-input-validation
            v-model="myVar.memo"
            :disabled="isShow"
            :label="$t('description') + ' (' + $t('optional') + ')'"
            vid="memo"
            :placeholder="placeholders.config_values.description"
            input-type="textarea"
            rows="2"
            auto-grow
          />
        </div>

        <div class="column is-4-tablet is-12-mobile">
          <b-switch
            v-model="myVar.fo"
            :disabled="isShow"
          >
            {{
              $t("fo")
            }}
          </b-switch>
        </div>

        <div class="column is-4-tablet is-12-mobile">
          <b-switch
            v-model="myVar.system"
            :disabled="isShow || !$can('update', 'settings.variables.system')"
          >
            {{ $tc("system", 1) }}
          </b-switch>
        </div>

        <div class="column is-4-tablet is-12-mobile">
          <b-switch
            v-model="myVar.active"
            :disabled="isShow"
          >
            {{
              $tc("active", 1)
            }}
          </b-switch>
        </div>

        <div
          v-if="myVar.type == 'site'"
          class="column is-12 columns is-multiline"
        >
          <div
            v-for="item in items"
            :key="`tab-item-${item.site_id}`"
            class="column is-12-mobile is-6-tablet p-2"
          >
            <b>
              <img
                height="20"
                width="20"
                :src="'/img/sites/' + item.site_id + '.svg'"
              >
              {{ item.site.label }}
            </b>
            <div class="mt-2">
              <b-input-validation
                v-model="item.value"
                :disabled="isShow"
                :label="$tc('value', 1)"
                vid="value"
                :placeholder="placeholders.config_values.value"
              />
            </div>
          </div>
        </div>

        <div
          v-else-if="myVar.type == 'tld'"
          class="column is-12"
        >
          <div
            v-for="(item, i) in items"
            :key="`tab-item-${item.site_id}-${item.tld_id}-${item.lang_iso}`"
          >
            <div
              :class="(i + 1) % 2 == 0 ? 'bg-cream' : ''"
              class="columns is-multiline is-mobile is-variable is-2"
            >
              <div class="column is-12">
                <img
                  height="20"
                  width="20"
                  :src="'/img/sites/' + item.site_id + '.svg'"
                >
                <b>
                  {{ item.site.label }}
                </b>
                ({{ item.tld.country.label }}) 
              </div>

              <div class="column is-5">
                <b-select-validation
                  v-model="item.lang_iso"
                  vid="lang_iso"
                  :disabled="isShow"
                  :label="$tc('language', 1)"
                  @input="addNewVar(item, index)"
                >
                  <template>
                    <option
                      v-for="opt in item.languages"
                      :key="opt.iso"
                      :value="opt.iso"
                    >
                      {{ opt.label }}
                    </option>
                  </template>
                </b-select-validation>
              </div>

              <div class="column is-7">
                <b-input-validation
                  v-model="item.value"
                  :disabled="isShow"
                  :label="$tc('value', 1)"
                  vid="value"
                  :placeholder="placeholders.config_values.value"
                />
              </div>
            </div>
          </div>
        </div>

        <template v-else>
          <div class="column is-12">
            <b-input-validation
              v-model="singleItem.value"
              :disabled="isShow"
              :label="$tc('value', 1)"
              vid="value"
              :placeholder="placeholders.config_values.value"
            />
          </div>
        </template>

        <div class="column">
          <b-button
            type="is-primary"
            :disabled="loading || isShow"
            :loading="loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    index: {
      type: [String, Number],
      required: true
    },
    variable: {
      type: Object,
      required: true
    },
    types: {
      type: Array,
      default: () => []
    },
    action: {
      type: String,
      default: ""
    },
    sites: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isShow: this.action == "read",
      loading: false,
      myVar: {
        type: null,
        var: "",
        fo: false,
        memo: "",
        active: true,
        system: false
      },
      items: [],
      singleItem: {}
    };
  },
  watch: {
    "myVar.type"(newVal) {
      this.setVariables(newVal);
    }
  },
  created() {
    if (this.variable.id) {
      this.myVar = { ...this.variable };
    }
    this.setVariables(this.variable.type || this.types[0]);
  },
  methods: {
    setVariables(type) {
      if (type == "site") {
        this.items = this.sites.reduce((arr, s) => {
          let value;
          if (this.variable.type == type) {
            value = this.variable.values.find(v => v.site_id == s.id);
          }
          return [
            ...arr,
            {
              site: s,
              site_id: s.id,
              id: value && value.id,
              value: value && value.value,
              lang_iso: value && value.lang_iso,
              languages: s.languages
            }
          ];
        }, []);
      } else if (type == "tld") {
        this.items = this.sites.reduce((arr, s) => {
          let tlds = s.tlds.reduce((arr2, tld) => {
            let value =
              this.variable.values &&
              this.variable.values.find(
                v => v.site_id == s.id && v.tld && v.tld.id == tld.id
              );
            return [
              ...arr2,
              {
                site: s,
                site_id: s.id,
                tld: tld,
                tld_id: tld.id,
                id: value && value.id,
                value: value && value.value,
                lang_iso: value && value.lang_iso,
                languages: tld.languages || s.languages
              }
            ];
          }, []);
          return [...arr, ...tlds];
        }, []);
      } else {
        if (this.variable.type == type) {
          this.singleItem = {
            id: this.variable.values[0].id,
            value: this.variable.values[0].value
          };
        } else {
          this.singleItem = {
            id: null,
            value: null
          };
        }
      }
    },
    addNewVar(item, index) {
      if (item.languages.length > 1)
        this.items.splice(index + 1, 0, {
          ...item,
          lang_iso: null,
          value: null,
          id: null
        });
    },
    saveVar() {
      this.loading = true;
      let data = {
        type: this.myVar.type,
        var: this.myVar.var,
        fo: this.myVar.fo,
        memo: this.myVar.memo,
        active: this.myVar.active,
        system: this.myVar.system,
        values: []
      };

      if (data.type == "site" || data.type == "tld") {
        data.values = this.items.reduce((arr, v) => {
          if (!v.value) return arr;
          return [
            ...arr,
            {
              id: v.id,
              site_id: v.site_id||  null,
              tld_id: v.tld_id || null,
              value: v.value|| null,
              lang_iso: v.lang_iso || null,
            }
          ];
        }, []);
      } else {
        data.values.push({
          value: this.singleItem.value,
          id: this.singleItem.id
        });
      }

      this.$axios({
        method: this.action == "create" ? "POST" : "PUT",
        url:
          this.action == "create"
            ? `variables`
            : `variables/${this.variable.id}`,
        data: data
      }).then(res => {
        this.$root.notify(
          this.$t(
            this.action == "create"
              ? "successfully_created"
              : "successfully_updated"
          ),
          "is-success"
        );
        this.$bus.$emit(
          this.action == "create" ? "add-variable" : "update-variable",
          res.data
        );
        this.$root.closeDrawer();
      })
      .finally(() => (this.loading = false));;
    }
  }
};
</script>

