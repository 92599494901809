<template>
  <div class="wrap">
    <template v-if="loading">
      <div class="columns is-variable is-1 is-multiline is-mobile mt-5">
        <div class="column is-3">
          <b-skeleton
            width="120px"
            height="8px"
            animated
          />
        </div>
        <div class="column is-9 has-text-right ellipsis">
          <b-skeleton
            width="100%"
            height="8px"
            animated
          />
        </div>

        <div class="column is-3">
          <b-skeleton
            width="150px"
            height="8px"
            animated
          />
        </div>
        <div class="column is-9 has-text-right">
          <b-skeleton
            width="100%"
            height="8px"
            animated
          />
        </div>

        <div class="column is-3">
          <b-skeleton
            width="80px"
            height="8px"
            animated
          />
        </div>
        <div class="column is-9 has-text-right">
          <b-skeleton
            width="100%"
            height="8px"
            animated
          />
        </div>
      </div>

      <div>
        <b-skeleton
          width="100%"
          height="300px"
          animated
        />
      </div>
    </template>

    <template v-else>
      <div class="columns is-variable is-1 is-multiline is-mobile mt-5">
        <div class="column is-3">
          <span class="has-text-grey-light">{{ $t("subject") }}</span>
        </div>
        <div class="column is-9 has-text-right">
          <span>{{ mail.subject }}</span>
        </div>

        <div class="column is-3">
          <span class="has-text-grey-light">{{ $t("email") }}</span>
        </div>
        <div class="column is-9 has-text-right ellipsis">
          <span>{{ mail.email }}</span>
        </div>

        <!-- <div class="column is-3">
          <span class="has-text-grey-light">{{ $t("date") }}</span>
        </div>
        <div class="column is-9 has-text-right ellipsis">
          <span>{{ mail.date.formatted }}, {{ mail.date.time }}</span>
        </div> -->

        <template v-for="evt in mail.events">
          <div
            :key="evt.name + '-name'"
            class="column is-3"
          >
            <span class="has-text-grey-light">{{ capitalize(evt.name) }}</span>
          </div>
          <div
            :key="evt.name + '-date'"
            class="column is-9 has-text-right ellipsis"
          >
            <span>{{ evt.date.formatted }}, {{ evt.date.time }}</span>
          </div>
        </template>
      </div>

      <div v-html="mail.newContent" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      mail: {},
      styleId: 'temp-style'
    };
  },
  created() {
    this.loading = true;
    this.$axios
      .get(`users/crm/details/${this.options.uuid}`)
      .then((res) => {
        this.mail = res.data;
        let content = res.data.content;
        let lastIndex = content.lastIndexOf('}');
        let style = content.substr(0, lastIndex + 1);
        this.addStyle(style);
        res.data.newContent = content.substr(lastIndex + 1, content.length);
      })
      .catch((e) => this.clientError(e))
      .finally(() => (this.loading = false));
  },
  beforeDestroy(){
    var element = document.getElementById(this.styleId);
    element.parentNode.removeChild(element);
  },
  methods: {
    capitalize(text){
      return text.charAt(0).toUpperCase() + text.substr(1, text.length);
    },
    addStyle(styleString){
      const style = document.createElement('style');
      style.textContent = styleString;
      style.id = this.styleId;
      document.head.append(style);
    }
  }
};
</script>

<style>
</style>
