<template>
  <div class="wrap">
    <ValidationObserver
      ref="observer"
      v-slot="{ handleSubmit }"
      class="mt-2"
      tag="div"
    >
      <form
        method="post"
        autocomplete="off"
        @submit.prevent="options.add ? addNewCountry() : handleSubmit(updateCountry)"
      >
        <p class="title is-size-4-mobile mb-5">
          {{ options.add ? $t("add_country") : $t("edit_noun") }}
        </p>
        <div class="columns is-multiline">
          <b-input-validation        
            v-model="country.iso"
            :label="$t('iso')"
            :placeholder="placeholders.country.iso"
            disabled
            rules="required"
            size="is-small"
            class="column is-6 "
          />
          <b-input-validation            
            v-model="country.continent"
            :label="$t('continent')"
            :placeholder="placeholders.country.continent"
            :disabled="!$can('update', 'international.countries')"
            rules="required"
            size="is-small"
            class="column is-6"
          />     
          <b-input-validation            
            v-model="country.code"
            :label="$t('country_code')"
            :placeholder="placeholders.country.code"
            :disabled="!$can('update', 'international.countries')"
            rules="required"
            size="is-small"
            class="column is-6"
          />  
          <b-input-validation            
            v-model="country.vat_display"
            :label="`${$t('vat_display')} (${$t('optional')})`"
            :placeholder="placeholders.country.vat_display"
            :disabled="!$can('update', 'international.countries')"
            size="is-small"
            class="column is-6"
          />  
          <b-input-validation            
            v-model="country.vat_iso"
            :label="`${$t('vat_iso')} (${$t('optional')})`"
            :placeholder="placeholders.country.iso"
            :disabled="!$can('update', 'international.countries')"
            size="is-small"
            class="column is-6"
          /> 

          <div class="column is-6-tablet is-12-mobile">
            <b-field
              :label="`${$t('vat_checker')} (${$t('optional')})`"
            >
              <b-select
                v-model="country.vat_checker"
                size="is-small"
                :placeholder="$t('choose')"  
                :disabled="!$can('update', 'international.countries')"                 
                expanded
              >
                <option :value="null">
                  {{ $t('choose') }}
                </option>
         
                <option
                  v-for="opt in options.filters.vat_checkers"
                  :key="opt"
                  :value="opt"
                >
                  {{ opt }}
                </option> 
              </b-select>
            </b-field>
          </div>
     
          <div class="column is-6 mb-2">
            <b-switch
              v-model="country.proforma"
            >
              {{ $t("invoice_car") }}
            </b-switch>
          </div> 
          <div
            v-if="($can('delete', 'international.countries'))"
            class="column is-6"
          >
            <b-switch
              v-model="country.active"
            >
              {{ $tc("active", 1) }}
            </b-switch>
          </div> 
        </div>
        <p class="title is-size-5 mb-5">
          {{ $tc('translation', 2) }}
        </p> 
        <div class="columns is-multiline">
          <div
            v-for="item in country.trans"
            :key="item.language.label"
            class="column is-6"
          >	  
            <div
              class="field has-addons"
            >
              <div class="control is-expanded">
                <b-input-validation            
                  v-model="item.country"
                  :label="item.language.label"
                  rules="required"
                  size="is-small"
                  disabled
                  expanded
                />
              </div> 
              <p
                v-if="item.main"
                class="control"
              >
                <slot name="suffix">
                  <span
                    class="button is-static is-small"
                  >
                    <b-icon
                      icon="check"
                      type="is-success"
                      size="is-small"
                    />
                  </span>
                </slot>
              </p>
            </div>
          </div>
        </div>
    
        <b-button
          type="is-primary"
          :disabled="!$can('update', 'international.countries') || loading"
          :loading="loading"
          tag="input"
          native-type="submit"
          :value="$t('validate')"
        >
          {{ $t("validate") }}
        </b-button>
      </form>
    </ValidationObserver>
  </div>
</template>
  
<script>
  
export default {	
	props: {
	  options: {
		type: Object,
		required: false,
		default: null
	  }
	}, 
	data() {
    	return {
	 		country: Object.assign({}, this.options.list),
	 		};
	    },
  
	methods: {
		addNewCountry() {
			this.loading = true;
			this.$axios
				.post(`/countries`, {  
					country_iso : this.country.iso,
					continent : this.country.continent,
					code : this.country.code,
					trans : [{ lang_iso : null,
							country : null,
							main : null }],
					// trans : [],

				})
				.then(res => {
				
				this.$root.notify(this.$t("successfully_created"), "is-success");
				})
				.catch(e => this.$root.clientError(e))
				.finally(() => (this.loading = false));
		},
		updateCountry() {
			this.loading = true;			
			let trans = [];
			for (let i = 0; i< this.country.trans.length; i++) {
				var obj = { lang_iso : this.country.trans[i].language.iso,
							country : this.country.trans[i].country,
							main : this.country.trans[i].main,}
				trans.push(obj);
			}
			this.$axios
				.put(`/countries/${this.options.list.iso}`, {  
					active : this.country.active,
					proforma : this.country.proforma,
					country_iso : this.country.iso,
					continent : this.country.continent,
					code : this.country.code,
					trans : trans,
					vat_checker : this.country.vat_checker,
					vat_display : this.country.vat_display,
					vat_iso : this.country.vat_iso,

				})
				.then(res => {
					this.options.list.active = res.data.active;
					this.options.list.proforma = res.data.proforma;
					this.options.list.iso = res.data.iso;
					this.options.list.continent = res.data.continent;
					this.options.list.code = res.data.code;
					this.options.list.vat_iso = res.data.vat_iso ;
					this.options.list.vat_checker = res.data.vat_checker;
					this.options.list.vat_display = res.data.vat_display;
					this.close();
					this.$root.notify(this.$t("successfully_updated"), "is-success");
				})
				.catch(e => this.$root.clientError(e))
				.finally(() => (this.loading = false));
		},
	    close() {
		    this.$root.closeDrawer();	
	   },	
	}
  };
  </script>