<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ title }}
    </p>
    <ReviewForm :review="options.review" />
  </div>
</template>

<script>
import ReviewForm from "@/components/forms/Review.vue";
export default {
  components: { ReviewForm },
  props: {
    options: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      title: null
    };
  },
  created() {
    this.title = this.$t("reviews") + ": " + this.options.review.product.name;
  }
};
</script>
