<template>
  <div class="wrap">
    <p class="title is-size-4-mobile mb-5">
      {{ title }}
    </p>
    <ConfigVarsForm
      v-if="!loading"
      :index="options.index"
      :variable="variable"
      :types="options.types"
      :sites="sites"
      :action="action"
    />
  </div>
</template>

<script>
import ConfigVarsForm from "@/components/forms/ConfigVars";

export default {
  components: {
    ConfigVarsForm
  },
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      title: "",
      action: "",
      variable: {},
      sites: [],
      loading: true
    };
  },
  created() {
    if (this.options.action == "read") {
      if (this.$can("update", "settings.variables")) {
        this.action = "update";
        this.title = this.$t("edit_noun");
      } else if (this.$can("read", "settings.variables")) {
        this.action = "read";
        this.title = this.$tc("variable", 1);
      }
    } else if (this.$can("create", "settings.variables")) {
      this.action = "create";
      this.title = this.$t("new_variable");
    }
    this.getVars();
  },
  methods: {
    getVars() {
      this.loading = true;

      this.$axios
        .get(
          "/variables/" +
            (this.options.variable.id ? this.options.variable.id : 0)
        )
        .then(({ data }) => {
          if (data.var && data.var.system) {
            if (
              this.action == "update" &&
              !this.$can("update", "settings.variables.system")
            ) {
              this.action = "read";
              this.title = this.$tc("variable", 1);
            }
          }
          this.variable = data.var || {};
          this.sites = data.filters.sites;

          // this.items = data.var.values;
          // let index = this.headers.findIndex(h => h.value == "site_id");
          // this.headers[index].filter_values = data.filters.sites;
          // index = this.headers.findIndex(h => h.value == "tld_id");
          // this.headers[index].filter_values = data.filters.tlds;
          // this.types = data.filters.types;
          // this.sites = data.filters.sites;
          // this.tlds = data.filters.tlds;
          // this.total = this.items.length;
          // this.var = data.var;
          this.loading = false;
        });
    }
  }
};
</script>

