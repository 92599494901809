<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit }"
    tag="div"
  >
    <form
      method="post"
      autocomplete="off"
      @submit.prevent="handleSubmit(savePage)"
    >
      <div class="columns is-multiline is-mobile">
        <div class="column is-4-tablet is-12-mobile">
          <b-input-validation
            v-model="myPage.prefix"
            :disabled="
              disableInputs ||
                (!$can('update', 'settings.pages.routes') && action != 'create')
            "
            :label="$t('prefix') + ' (' + $t('optional') + ')'"
            vid="trigger"
            :placeholder="placeholders.page.prefix"
          />
        </div>

        <div class="column is-4-tablet is-12-mobile">
          <b-input-validation
            v-model="myPage.route"
            :disabled="
              disableInputs ||
                (!$can('update', 'settings.pages.routes') && action != 'create')
            "
            :label="$t('route')"
            vid="trigger"
            rules="required"
            :placeholder="placeholders.page.route"
          />
        </div>

        <div class="column is-4-tablet is-12-mobile">
          <b-input-validation
            v-model="myPage.rank"
            :disabled="disableInputs"
            :label="$t('rank') + ' (' + $t('optional') + ')'"
            input-type="number"
            :placeholder="placeholders.number['1']"
          />
        </div>

        <div class="column is-4-tablet is-12-mobile">
          <b-switch
            v-model="myPage.noindex"
            :disabled="disableInputs"
          >
            {{
              $t("noindex")
            }}
          </b-switch>
        </div>

        <div class="column is-4-tablet is-12-mobile">
          <b-switch
            v-model="myPage.static"
            :disabled="disableInputs"
          >
            {{
              $tc("static", 1)
            }}
          </b-switch>
        </div>

        <div class="column is-4-tablet is-12-mobile">
          <b-switch
            v-model="myPage.active"
            :disabled="disableInputs || !$can('delete', 'settings.pages')"
          >
            {{ $tc("active", 1) }}
          </b-switch>
        </div>

        <div class="column is-12">
          <b-button
            type="is-primary"
            :disabled="loading || disableInputs"
            :loading="loading"
            tag="input"
            native-type="submit"
            :value="$t('save')"
          >
            {{ $t("save") }}
          </b-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true
    },
    index: {
      type: [Number, String],
      required: true
    },
    action: {
      type: String,
      default: "read"
    }
  },
  data() {
    return {
      loading: false,
      disableInputs: false,
      myPage: {
        prefix: null,
        route: null,
        rank: null,
        noindex: false,
        active: true
      }
    };
  },
  created() {
    if (this.page.id) {
      this.myPage.prefix = this.page.prefix;
      this.myPage.route = this.page.route;
      this.myPage.rank = this.page.rank;
      this.myPage.noindex = this.page.noindex;
      this.myPage.active = this.page.active;
      this.myPage.static = this.page.static;
    }

    if (!this.action == "read") this.disableInputs = true;
  },
  methods: {
    savePage() {
      this.loading = true;

      this.$axios({
        method: this.action == "create" ? "POST" : "PUT",
        url: this.action == "create" ? `pages` : `pages/${this.page.id}`,
        data: this.myPage
      })
        .then(res => {
          this.$bus.$emit("update-item", { page: res.data, index: this.index });
          this.$root.notify(
            this.$t(
              this.action == "create"
                ? "successfully_created"
                : "successfully_updated"
            ),
            "is-success"
          );
          this.$root.closeDrawer();
        })
        .catch(e => this.clientError(e))
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

